import update from 'immutability-helper';
import { SingInActionTypes, SingInActions } from './action';

export interface SingInState {
  email: string;
  password: string;
}

export const InitialSingInState: SingInState = {
  email: '',
  password: '',
};

const singInreducer = (state = InitialSingInState, action: SingInActions) => {
  switch (action.type) {
    case SingInActionTypes.SING_IN_EMAIL_FIELDS:
      return update(state, {
        email: { $set: action.email },
      });
    case SingInActionTypes.SING_IN_PASSWORD_FIELDS:
      return update(state, {
        password: { $set: action.password },
      });
    default:
      return state;
  }
};

export default singInreducer;
