import update from 'immutability-helper';
import Pages from '../../../../helpers/loginPages';
import { PrimiaryInterfaceActionTypes, PrimiaryInterfaceActions } from './action';

export interface PrimiaryInterfaceState {
  authModal: boolean;
  currentPage: Pages;
  history: Pages[];
}

export const InitialPrimiaryInterfaceState: PrimiaryInterfaceState = {
  authModal: false,
  currentPage: Pages.InitialPage,
  history: [],
};

const primiaryInterfaceRreducer = (state = InitialPrimiaryInterfaceState, action: PrimiaryInterfaceActions) => {
  switch (action.type) {
    case PrimiaryInterfaceActionTypes.OPEN_AUTH_MODAL:
      return update(state, {
        authModal: { $set: true },
      });

    case PrimiaryInterfaceActionTypes.CLOSE_AUTH_MODAL:
      return update(state, {
        authModal: { $set: false },
      });

    case PrimiaryInterfaceActionTypes.SET_CURRENT_PAGE: {
      return update(state, {
        currentPage: { $set: action.stage },
        history: { $push: state.currentPage === Pages.InitialPage ? [] : [state.currentPage] },
      });
    }

    case PrimiaryInterfaceActionTypes.GO_TO_PREVIOUS_PAGE: {
      const lastPage = state.history[state.history.length - 1];

      if (!lastPage) {
        return update(state, {
          currentPage: { $set: Pages.InitialPage },
          history: { $set: [] },
        });
      }

      return update(state, {
        currentPage: { $set: lastPage },
        history: { $splice: [[state.history.length - 1, 1]] },
      });
    }

    default:
      return state;
  }
};

export default primiaryInterfaceRreducer;
