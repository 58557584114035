/* External dependencies */
import { applyMiddleware, combineReducers, createStore as createReduxStore, Store } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension/logOnlyInProduction';
import { combineEpics, createEpicMiddleware } from 'redux-observable';
import { getDevices } from '../devices/getDevices/redux/action';
import primiaryInterfaceRreducer from '../components/workspace/primaryInterface/redux/reducer';
import singInreducer from '../components/workspace/authComponents/singIn/redux/reducer';

/* Local dependencies */

const rootEpic = combineEpics();

const rootReducer = combineReducers({
  getDevices,
  primiaryInterfaceRreducer,
  singInreducer
});

let store;

export function createStore(): Store {
  const epicMiddleware = createEpicMiddleware();
  store = createReduxStore(rootReducer, composeWithDevTools(applyMiddleware(epicMiddleware)));

  epicMiddleware.run(rootEpic);

  return store;
}

export type RootState = ReturnType<typeof store.getState>;
