enum Pages {
  InitialPage = 'InitialPage',
  LoadingPage = 'LoadingPage',

  SingInPage = 'SingInPage',

  RegistrationEmailPage = 'RegistrationEmailPage',
  RegistrationPasswordPage = 'RegistrationPasswordPage',
  RegistrationCodePage = 'RegistrationCodePage',

  PasswordResetEmailPage = 'PasswordResetEmailPage',
  PasswordResetCodePage = 'PasswordResetCodePage',
  PasswordResetPasswordPage = 'PasswordResetpasswordPage',
}

export default Pages;
