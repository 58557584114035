export enum SingInActionTypes {
  SING_IN_EMAIL_FIELDS = 'SING_IN_EMAIL_FIELDS',
  SING_IN_PASSWORD_FIELDS = 'SING_IN_PASSWORD_FIELDS',
}

export interface SingInEmailFields {
  type: SingInActionTypes.SING_IN_EMAIL_FIELDS;
  email: string;
}

export interface SingInPasswordFields {
  type: SingInActionTypes.SING_IN_PASSWORD_FIELDS;
  password: string;
}

export function singInEmailFields(email: string): SingInEmailFields {
  return {
    type: SingInActionTypes.SING_IN_EMAIL_FIELDS,
    email,
  };
}

export function singInPasswordFields(password: string): SingInPasswordFields {
  return {
    type: SingInActionTypes.SING_IN_PASSWORD_FIELDS,
    password,
  };
}

export type SingInActions = SingInEmailFields | SingInPasswordFields;
